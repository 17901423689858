<template>

   <iframe v-if="reportType==0" name = "child" id = "child" v-bind:src="reportUrl"
   width="100%" height="100%"
     frameborder="0" scrolling="no" 
    ></iframe>

</template>
 
<script>
// import {
//   getFile
// } from '@/api/report'
export default {
  mounted() {
    /**
      * iframe-宽高自适应显示
      */
    function changeMobsfIframe() {
      const mobsf = document.getElementById('child')
      const deviceWidth = document.body.clientWidth
      const deviceHeight = document.body.clientHeight
       mobsf.style.width = (Number(deviceWidth) - 300) + 'px' // 数字是页面布局宽度差值
      mobsf.style.height = (Number(deviceHeight) - 80) + 'px' // 数字是页面布局高度差
    }
 
    changeMobsfIframe()
 
    window.onresize = function() {
      changeMobsfIframe()
    }
  },
 
  data() {
    return {
      htmlContent: '',
      reportUrl: '',
      reportType: ''
    }
  },
  created() {
    console.log(this.$route.query.reportUrl)
    // this.fileName = '../static/file/' + this.$route.params.report_url
    this.reportUrl = this.$route.query.reportUrl
    this.reportType = this.$route.query.reportType
    // if (this.reportType == 1) {
    //   getFile(this.reportUrl).then(res => {
    //     if (res.code === 200) {
    //       this.htmlContent = res.data
    //     }
    //   })
    // }
  }
}
</script>
<style>
.iframeCss .container .content-container{
    padding: 0;
}
</style>
